<template>
  <div>
    <content-template
      :menuName="$t('cfcDetail.title')"
      :backButton="true"
      @backToPage="moveToOther('CFC')"
    >
      <template #detail>
        <table v-if="!isMobile">
          <tr v-for="(t, i) in fields" :key="`task-${i}`" class="s-flex s-mt-0">
            <div>
              <td
                :class="`s-input-label ${
                  isMobile ? 's-wd-100-min' : 's-wd-200-min'
                }`"
              >
                {{ t.name }}
              </td>
              <td>:</td>
              <td v-if="t.variable !== 'created_at'">
                {{ edited_item[t.variable] }}
              </td>
              <td v-if="t.variable !== 'hazard'">
                {{ lang == "id" ? hazard_idn : hazard_en }}
              </td>
              <td v-else>
                {{ convertDate(edited_item[t.variable]) }}
              </td>
            </div>
          </tr>
        </table>

        <div v-else>
          <div v-for="(t, i) in fields" :key="`task-alt-${i}`">
            <div class="i-table-cell-alt">
              <span class="i-input-label"
                ><b>{{ $t(t.name) }}</b></span
              >
              <br />
              <span v-if="t.variable !== 'created_at'" class="s-text-black">
                {{ edited_item[t.variable] }}
              </span>
              <span v-else class="s-text-black">
                {{ convertDate(edited_item[t.variable]) }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import form from "@/mixins/form";
import ContentTemplate from "./contentTemplate.vue";
import { mapActions, mapGetters } from "vuex";
import { convertDate } from "@/common/timeformat";

export default {
  components: { ContentTemplate },
  props: ["inputType", "item"],
  mixins: [form],
  data() {
    return {
      edited_item: {
        location: "",
        hazard: "",
        is_danger: "",
        comment: "",
        created_at: "",
      },

      fields: [
        { name: "Location", variable: "location" },
        { name: "Hazard", variable: "hazard" },
        { name: "Dangerous?", variable: "is_danger" },
        { name: "Comment", variable: "comment" },
        { name: "Date Created", variable: "created_at" },
      ],
    };
  },
  created() {
    this.initData();
  },
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      role_type: "auth/getRole",
      item: "cfc/getFeedback",
    }),
  },
  methods: {
    ...mapActions({
      setFeedbackSingleData: "cfc/setFeedbackSingleData",
    }),

    async initData() {
      await this.setFeedbackSingleData(this.$route.params.id);
      this.edited_item = Object.assign({}, this.item);
      this.edited_item.hazard = this.item.hazard_en;
    },

    moveToFinish() {
      this.$router.push(this.$translate({ name: "APMA Finish Task" }));
    },

    convertDate(value) {
      return convertDate(value);
    },
  },
};
</script>